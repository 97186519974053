// var sql = require('mssql');

export class DBAdapter {
    _conn;
    _DBConfig;
    _rpcClientAdapter;
    _rpcServerAdapter;
    _isServer = false;

    constructor(DBConfig, rpcClientAdapter, rpcServerAdapter) {
        this._DBConfig = DBConfig;
        // mqRPC adapter
        this._rpcClientAdapter = rpcClientAdapter;
        // mqServer adapter
        this._rpcServerAdapter = rpcServerAdapter;
        // track external
        window.$integration = {
            server: false,
            client: false 
        };
        window.$system = {
            connected: false
        }
        // // register stream
        // sql.on('error', err => {
        //     throw new Error(err);
        // });
    }

    query(queryString, attributes = [], tolerant = true) {

        return new Promise((resolve ) => {
            this._rpcClientAdapter({
                    queryString,
                    attributes
                },
                function rpcQueryResults(results) {
                    window.$integration.client = true;
                    // resolve response
                    resolve(results);
                }
            );
        });

        // console.log(queryString, attributes, tolerant);
        return new Promise((resolve, reject) => {
            // final results either by direct connection or MQ
            let resultsPromise;

            ///////////////////////////////////////////////////////////
            // FIXME for some reason, the pool only does one request //
            ///////////////////////////////////////////////////////////

            // try direct connection
            // if ( this._conn && this._conn.connected ) {
            //     resultPromise = this._handleConnectionResolve(this._conn, queryString, attributes);
            // }else{
            // console.error(e);
            // if failed direct connection, try to re-establish it 
            resultsPromise = this._connect().then((pool) => {
                this._conn = pool;
                // connection pool means your a server and act like it
                if (!this._isServer) {
                    console.info('registered as a server');
                    this._rpcServerAdapter.init();
                    this._isServer = true;
                    window.$integration.server = true;
                    window.$integration.client = false;
                    window.$system.connected = true;
                }
                // now handle queries
                return this._handleConnectionResolve(pool, queryString, attributes);
            }, (err) => {
                // if failed establishment of connection then go to MQ
                if (tolerant) {
                    // if you fall back, your not a server anymore
                    if (this._isServer) {
                        console.info('unregistered as a server');
                        this._rpcServerAdapter.disconnect();
                        this._isServer = false;
                        window.$integration.client = true;
                        window.$integration.server = false;
                    }
                    // DB Connection
                    window.$system.connected = false;
                    // console.warn('Fallback to diva-queue!');
                    // for client handling
                    return this._handleConnectionReject(err, queryString, attributes)
                } else {
                    // MQ has failed and all process has been stopped.
                    reject('ERR: Can\'t process request >> ' + JSON.stringify(err));
                }
            });
            // }

            // final results
            resultsPromise.catch(e => console.log).then((result, err) => {
                if (err) {
                    reject(err);
                }
                resolve(result);
            }, (err) => {
                console.error(err);
                reject(err);
            });
        });
    }

    _connect() {
        return new sql.ConnectionPool({
            ...this._DBConfig,
            // connections should be fast or else they are not local
            connectionTimeout: 1500
        }).connect();
    }

    _handleConnectionResolve(pool, queryString, attributes) {
        // get connection
        let request = pool.request()
        if (attributes.length > 0) {
            attributes.map(inputRef => {
                // add inputs
                // eval is used since the limitations of stringify can't take function members ( sql.VarChar(50) fails )
                request.input(inputRef.param, eval(inputRef.type), inputRef.value)
            })
        }
        // send the query
        return request.query(queryString)
    }


    _handleConnectionReject(err, query, attributes) {
        // Client outside of network, lets pick a worker to help
        return new Promise((resolve, reject) => {
            if (
                err.name === "ConnectionError" &&
                // connection error or timeout ocnnection 
                (err.code === "ESOCKET" || err.code === "ETIMEOUT")
            ) {
                // offsite rpc from DBConnection
                this._rpcClientAdapter({
                        query,
                        attributes
                    },
                    function rpcQueryResults(results) {
                        window.$integration.client = true;
                        // resolve response
                        resolve(results);
                    }
                );
            } else {
                window.$integration.client = false;
                resolve(null, err);
            }
        })
    }

}